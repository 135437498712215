<template>
	<section style="width: 80%;">
		<h1>Liste package question flash</h1>
		<pagination-component
			v-if="pages > 1"
			:totalPages="pages"
			:perPage="20"
			:currentPage="currentPage"
			@pageChanged="getPackages"
		/>
		<FilterWrapper  @updateFilter="updateFilter" :show-search="false" :show-filter="false" />
		<select v-model="filters.status">
			<option value="VALIDATED">Validé</option>
			<option value="PENDING">en attente</option>
			<option value="REFUSED">refusé</option>
		</select>
		
		<table style="width: 100%;">
			<tr>
				<th></th>
				<th>Titre</th>
				<th>Auteur</th>
				<th>Voir</th>
				<th>Modifier</th>
				<th>Supprimer</th>
				<th>Status</th>
			</tr>
			<tr v-for="(content, index) in packages" :key="index">
				<td>{{ index + 1 }}</td>
				<td>{{ content.title }}</td>
				<td>{{ content.teacher }}</td>
				
				<td>
					<button class="see" @click="showPopUpPreview(content)">
						<img src="/img/super-admin/blue-eye.svg" alt="" />
					</button>
				</td>
				<td>
					<router-link :to="{ name: 'SuperAdminEditQuestionFlashPackage', params: { id: content.id } }">
						Modifier
					</router-link>
				</td>
				<td>
					<button
						class="delete"
						v-if="checkIfSuperAdminAndValidatedStatus(content.status)"
						@click="deleteQuestion(content.id)"
					>
						<img src="/img/super-admin/blue-trash.svg" alt="" />
					</button>
				</td>
				<td> <span :style="getStyleColor(content.status)">{{  translate(content.status)  }}</span></td>
				
			</tr>
			
		</table>
		<Teleport to="body">
			<PopUpPreviewQuestionFlashPackage v-if="isPopUpPreviewVisible" :content="selectedPackage" @close="isPopUpPreviewVisible = !isPopUpPreviewVisible" @updateQcmPackage="removeQuestion" />
		</Teleport>
	</section>
</template>
<script>
import PaginationComponent from '../../Utils/PaginationComponent.vue'
import PopUpPreviewQuestionFlashPackage from '../../PopUp/QuestionFlashPackage/PopUpPreviewQuestionFlashPackage.vue'
import FilterWrapper from '../../Admin/Search/FilterWrapper.vue'
import { deleteQuestionFlashPackageService, filteredQuestionFlashPackagesService } from '../../../services/questionFlashPackagesServices'

export default {
	components: {
		PaginationComponent,
		PopUpPreviewQuestionFlashPackage,
		FilterWrapper
	},
	
	data() {
		return {
			packages: [],
			currentPage: 0,
			pages: 0,
			filters: {
				level: '',
				teacher: '',
				chapter: '',
				status: 'VALIDATED',
				text: ''
			},
			isPopUpPreviewVisible: false,
			selectedPackage: null,
		}
	},
	methods: {
		removeQuestion(id) {
			this.packages = this.packages.filter((question) => question.id !== id)
		},
		showPopUpPreview(question){
			this.isPopUpPreviewVisible = true
			this.selectedPackage = question
		},
		translate(status) {
			switch (status) {
				case 'VALIDATED':
					return 'Approuvé'
				case 'REFUSED':
					return 'Refusé'
				case 'PENDING':
					return 'En attente'
			}
		},
		getStyleColor(status) {
			switch (status) {
				case 'VALIDATED':
					return `color: #17A81C`
				case 'PENDING':
					return `color: #fff;background-color:#F0961A;`
				case 'REFUSED':
					return `color: #DE1D1D`
				case 'PRIVATE':
					return `color: #000`
			}
		},
		
		async getPackages(e){
			this.currentPage = e || 0
			const chapter = this.filters.chapter ? `chapter=${this.filters.chapter}` : 'chapter='
			const level = this.filters.level ? `&level=${this.filters.level}` : '&level='
			const teacher = this.filters.teacher ? `&teacher=${this.filters.teacher}` : '&teacher='
			const subject = this.filters.subject ? `&subject=${this.filters.subject}` : '&subject='
			this.filter = `?${chapter}${level}${teacher}${subject}&offset=${(this.currentPage) * 20}`
			const res = await filteredQuestionFlashPackagesService(this.filter,this.filters.status)
			this.packages = res.results
			const total = res.count
			this.pages = Math.ceil(total / 20)
		},
		checkIfSuperAdminAndValidatedStatus(status) {

			if (status == 'VALIDATED') {
				if (this.$store.state.user.role == 'SUPER_ADMIN')
					return true
				return false
			}
			return true
		},
		async deleteQuestion(id) {
			if (confirm("Do you really want to delete?")) {
				await deleteQuestionFlashPackageService(id)
				this.packages = this.packages.filter((question) => question.id !== id)
			}
		},
		updateFilter(data) {
			this.filters.level = data.level
			this.filters.subject = data.subject
			this.filters.chapter = data.chapter
			this.getPackages()
		},
		
	},

	watch: {
		'filters.status': {
			handler: function() {
				this.getPackages()
			},
			deep: true
		}
	},
	
}
</script>
