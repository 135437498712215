<template>
	<pop-up-wrapper @close="this.$emit('close')">
		<div class="qcm-package-preview">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<div class="package-container">
				<p class="title">{{ content.title }}</p>

				<div v-for="question in questions" :key="question.id">
					<p class="question">{{ question.title }}</p>
					<p>{{ question.description }}</p>
					<div v-if="question.displayedImg" class="img-container">
						<img width="200" :src="question.displayedImg" alt="">
					</div>	
				</div>
			</div>
			<SelectStatus v-if="checkRouteAdmin()" :id="content.id" @close="$emit('close')" @updateQuestion="$emit('updateQcmPackage', content.id)" :resource="'question-flash-packages'" />
		</div>
	</pop-up-wrapper>
</template>

<script>
import { getQuestionService } from '../../../services/allQuestionsService'
import { authenticatedImageFetch } from '../../../utils'
import PopUpWrapper from '../PopUpWrapper.vue'
import SelectStatus from '../QCM/SelectStatus.vue'
export default {
	components: { PopUpWrapper, SelectStatus },
	emits: ['close'],
	props: {
		content: Object,
	},
	data() {
		return {
			questions:[]
		}
	},
	

	methods: {
		checkRouteAdmin() {
			return this.$route.path.includes('/super-admin/')
		},
		async getImg(question){
			if(!question.image_url_1) return
			const res = await authenticatedImageFetch(question.image_url_1)
				.then(res => res.blob())

			return URL.createObjectURL(res)
		},
		async getQuestion(questionId){
			const res = await getQuestionService(questionId)
			return res
		},
		reRender() {
			if (window.MathJax) {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub])
			}
		},
		
	},
	async created() {
		
		
		for await(let questionId of this.content.questions) {
			const question = await this.getQuestion(questionId)
			question.displayedImg = await this.getImg(question)
			this.questions.push(question)
		}
		this.reRender()
	},
	

}
</script>

<style>
</style>
